<template>
<v-container>
        <v-row>
            <v-col cols=12 md=12 lg=12>
                <v-card class="component1">
                    <v-row>
                        <v-col align="center" height="100%" lg=5 md=4 cols=12 class="pa-0">
                            <v-img class="image" height="100%" max-width="270px" src="../../assets/maker.png" />
                        </v-col>
                        <v-col lg=5 md=5 cols=12>
                            <v-row dense>
                                <v-col cols=12 lg=12 class="text-center text-md-left">
                                    <span class="headline"> MakerDAO </span>
                                </v-col>
                                <v-col cols=12 lg=12 class="text-center text-md-left">
                                    <span class="subtitle-1 secondary--text">Current APR 2.4% </span>
                                </v-col>
                            </v-row>
                            <v-row dense>
                                <v-col cols=12 md=6 lg=6 class="text-center text-md-left">
                                    <span class="subtitle-1 grey--text">Invested</span>
                                </v-col>
                                <v-col cols=12 md=6 lg=6 class="text-center text-md-right">
                                    <span class="subtitle-1">$1532</span>
                                </v-col>                                
                                <v-col cols=12 md=6 lg=6 class="text-center text-md-left">
                                    <span class="subtitle-1 grey--text">Earned (24h)</span>
                                </v-col>
                                <v-col cols=12 md=6 lg=6 class="text-center text-md-right">
                                    <span class="subtitle-1"> $2.30</span>
                                </v-col>
                                <v-col cols=12 md=6 lg=6 class="text-center text-md-left">
                                    <span class="subtitle-1 grey--text">Daily Change</span>
                                </v-col>
                                <v-col cols=12 md=6 lg=6 class="text-center text-md-right">
                                    <span class="subtitle-1 profit--text">+2.4%</span>
                                </v-col>

                            </v-row>
                        </v-col>
  
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
</v-container>
</template>

<script>

   const gradients = [
        ['#20CCFF', '#FAF595', '#30FFF3'],
        ['#20CCFF', '#FAF595', '#E797FD'],
        ["#E797FD", "#FAF595"]
    ]

export default {
    components: {
    },

    data() {
        return {
        gradient: gradients[1],
        gradients,
        }
    }
    
}
</script>

<style scoped>

    .image {
        border-radius: 2%;
    }


</style>