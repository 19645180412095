<template>
    <v-container>
        <v-row dense>
            <v-col lg=12>
                <span class="headline">Invested</span>
            </v-col>
        </v-row>
        <v-row dense>
            <v-col lg=7 xl=5>
                <InvestedRow />
            </v-col>
            <v-col lg=7 xl=5>
                <InvestedRow />
            </v-col>

        </v-row>
        <v-row>
            <v-col lg=12>
                <span class="headline">Projects</span>
            </v-col>
        </v-row>
        <v-row align="center">
            <v-col align="center" cols=12 lg=4 xl=3>
               <ProjectCard :project="projects.makerdao" />
               
            </v-col>
            <v-col align="center" cols=12 lg=4 xl=3>
               <ProjectCard :project="projects.aave" />
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import ProjectCard from '../../components/Base/ProjectCard'
import InvestedRow from '../../components/Base/InvestedRow'
export default {
    components: {
        ProjectCard,
        InvestedRow
    },
    data() {
        return {
            projects: {
              makerdao: {
                name: "MakerDAO",
                cover: "maker.png",
                description: "Collateralized Loans",
                category: "Lending",
                volatility: "Low",
                insurable: "Yes",
                aum: "$1.2B", 
                minimum_investment_time: "30 Seconds",
                apr: "3.7%",
                risk: "A",
                launch_date: "14/04/2017"
            },
            aave: {
                name: "Aave",
                cover: "aave.jpg",
                description: "Flash Loans",
                category: "Lending",
                volatility: "Low",
                insurable: "Yes",
                aum: "$1.5B", 
                minimum_investment_time: "30 Seconds",
                apr: "3.7%",
                risk: "A",
                launch_date: "12/02/2018"
            }
        }}
    }
    
}
</script>