<template>
    <CategoryPage />
</template>

<script>

import CategoryPage from '../templates/CategoryPage'

export default {
    components: {
        CategoryPage
    },
    data() {
        return {
          
        }
    }
    
}
</script>