<template>
<v-container>
    <v-row>
        <v-col lg=12>
            <v-hover v-slot:default="{ hover } ">
            <v-card max-width="280px" link :to="propertyLink" :elevation="hover ? 12 : 2" color="component1" class="">
                    <v-img
                    :src="require(`@/assets/${project.cover}`)" max-width="280px" contain/>
                    <v-card-title class="pt-0 pb-0" primary-title>
                        <v-row no-gutters>
                            <v-col cols=12 lg=12 s=12>
                                <v-row>
                                    <v-col align="center" cols=12 lg=12 s=12>
                                        <span class="headline">{{ project.name }}</span>
                                    
                                    </v-col>
                                
                                    <v-col cols=12 lg=12 s=12>
                                        
                                                                <hr>
                                    
                                        <span class="subtitle-1 secondary--text"> {{ project.description }} </span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>

                                          
                    </v-card-title>
                    <v-card-text>

                        <v-row dense>
                            <v-col align="center" cols=6 lg=6> 
                                <v-row no-gutters> 
                                    <v-col lg=12 md=12> 
                                        Total AUM
                                    </v-col>
                                    <v-col lg=12>
                                        <span class="subtitle-1 white--text">{{ project.aum }}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col align="center" cols=6 lg=6> 
                                <v-row no-gutters> 
                                    <v-col lg=12 md=12> 
                                        Volatility 
                                    </v-col>
                                    <v-col lg=12 md=12>
                                        <span class="subtitle-1 white--text">{{ project.volatility }}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col  align="center" cols=6 lg=6> 
                                <v-row no-gutters> 
                                    <v-col lg=12 md=12 s=6> 
                                        Current APR
                                    </v-col>
                                    <v-col lg=12 md=12 s=6>
                                        <span class="subtitle-1 profit--text">{{ project.apr }}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col align="center" cols=6 lg=6> 
                                <v-row no-gutters> 
                                    <v-col lg=12 md=12 s=6> 
                                        Launched
                                    </v-col>
                                    <v-col lg=12 md=12 s=6>
                                        <span class="subtitle-1 white--text">{{ project.launch_date }}</span>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>                  
                    </v-card-text>

            </v-card>
        </v-hover>
        </v-col>
    </v-row>
</v-container>
</template>



<script>

export default {
    name: "PropertyCard",
    data: () => {
        return {
            value: 50,
            propertyLink: "/projects/test"
        }
    },
    props: ['project'],
    components: {
        
    },
    mounted() {
        console.log(this.props)
        console.log(this.$props.project)
    }
}
</script>


<style scoped>



    .v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>